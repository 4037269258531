import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import BlogFeed from "../components/blog-feed";
import CTA from "../components/cta";
import Layout from "../components/layout";
import Seo from "../components/seo"
import Quote from "../components/quote";
import patternHex from "../images/backrounds/pattern-hex-white.jpg";

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Zendesk Implementations"
        description="729 Solutions is a proud partner of Zendesk. Contact us to make the most of Zendesk through migrations, implementations and customizations."
      />
      <section>
        <div
          className="hero mb-5"
          style={{
            backgroundImage: `url(${patternHex})`,
          }}
        >
          <div className="hero-title bg-dark-transparent">
            <div className="hero-image">
              <StaticImage
                  src="../images/clients/zendesk.png"
                  alt="Zendesk Implementation Premier Partner"
                  layout="constrained"
                  style={{ maxWidth: "200px"}}
                />
            </div>
            <h1 className="text-white">A New Implementation of Zendesk</h1>
            <p className="text-white mb-6">
              Zendesk is the best customer experience software we know of –
              that’s why we are a certified development partner! However, it
              does need some expertise to set it up right from the beginning and
              get the most out of all its features.
            </p>
            <CTA
                href="/contact-us/"
                text="Tell us About Your Project"
                className="btn btn-primary"
              />
          </div>
        </div>
      </section>
      <section className="mb-6">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 mt-5 mt-lg-0 pb-5 pb-lg-0">
              <h2 style={{ fontSize: "2rem" }}>Your Business Is Unique</h2>
              <p>
                Each Zendesk will require customization to make sure your agents
                can efficiently take care of your unique customer needs. 729
                will help you configure Zendesk for your custom workflows and
                provide you our experience and expertise with dozens of
                implementations to make sure you set it up correctly from the
                start.
              </p>
              <p>
                Our certified admins can also walk you through automation,
                integrations, and agent best practices so you can manage and
                improve the tool yourself.
              </p>
            </div>
            <div className="col-12 col-lg-6 mt-xs-6 mt-sm-0">
              <h2 style={{ fontSize: "2rem" }}>Recent Zendesk Customers</h2>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="p-4">
                        <StaticImage
                          src="../images/clients/zendesk-customers/asg.png"
                          alt="Asg"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="p-4">
                        <StaticImage
                          src="../images/clients/zendesk-customers/n2y.png"
                          alt="Asg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="p-4">
                        <StaticImage
                          src="../images/clients/zendesk-customers/singularity.png"
                          alt="Asg"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="p-4">
                        <StaticImage
                          src="../images/clients/zendesk-customers/kidkraft.png"
                          alt="Asg"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="p-4">
                        <StaticImage
                          src="../images/clients/zendesk-customers/pureflix.png"
                          alt="Asg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dark pb-6">
          <div className="container">
            <div className="row py-6">
              <div className="col-12 text-center">
                <h2 className="text-white">Testimonials</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-4 pb-4 pb-lg-0">
                <Quote
                  dark
                  quote="Overwhelmingly, the feedback from our whole team was excellent."
                  name="Hillan Klein"
                  company="Namecheap"
                />
              </div>
              <div className="col-12 col-lg-4 pb-4 pb-lg-0">
                <Quote
                  dark
                  quote="When I have a question I know that I will get an answer that is easy for me to understand and not just a technical answer."
                  name="Sreedhar Reddy"
                  company="Larvol"
                />
              </div>
              <div className="col-12 col-lg-4">
                <Quote
                  dark
                  quote="Timely, appropriate, and always quick answers - which I enjoy."
                  name="Stuart Harris"
                  company="Alta Service"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-6">
        <div className="row">
          <div className="col">
            <div className="text-center p-5 bg-light">
              <h2>I Need a Quote</h2>
              <p>
                Reach out today. We’ll respond within 24 hours, and can scope
                your project within 3 days.
              </p>
              <CTA
                href="/contact-us/"
                text="Get a Quote"
                className="btn btn-primary"
              />
            </div>
          </div>
        </div>
      </section>
      <BlogFeed />
    </Layout>
  );
};

export default Index;
